export const Logo = () => {
  return (
    <svg 
      width="60px"
      height="60px"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path 
        d="M.912 1.094c-.055.277.02 16.717.075 16.75.026.017.844.034 1.817.034h1.771l.11-.113a8.42 8.42 0 0 0 .431-.61c.177-.273.815-1.24 1.415-2.15.6-.913 1.334-2.034 1.632-2.492.294-.458.847-1.307 1.22-1.882.379-.58.928-1.425 1.226-1.878.663-1.025 1.17-1.811 1.687-2.597.218-.332.394-.63.399-.668.004-.054-.06-.067-.382-.08-.214-.004-.424.009-.47.03-.042.025-.374.487-.739 1.033-.365.542-.675.988-.688.988-.013 0-.734-.706-1.603-1.567-2.538-2.521-4.8-4.731-4.951-4.84L3.723.947H.937l-.025.147ZM5.565 7.71c.693.689 1.427 1.407 1.633 1.6.205.194.57.547.814.782l.436.433-.264.407a71.25 71.25 0 0 1-.751 1.147 261.06 261.06 0 0 0-2.182 3.361c-.256.408-.99 1.47-1.016 1.47-.03 0-.071-10.347-.037-10.448.004-.03.033-.042.062-.025.026.017.613.588 1.305 1.273Z" fill="#000000"/><path d="M8.713 1.488c.302.294 1.091 1.072 1.754 1.719l1.208 1.184h2.77c2.677 0 2.765.005 2.765.08 0 .042-.214.4-.47.794a69.669 69.669 0 0 0-1.238 1.933c-.197.315-.44.697-.537.849-.096.15-.352.554-.566.903-.214.344-.512.815-.655 1.042-.147.222-.268.42-.268.433 0 .012-.08.134-.173.277-.096.139-.516.806-.94 1.479-.419.672-.94 1.504-1.157 1.844-.219.34-.5.786-.626.987-.126.206-.386.605-.57.891-.76 1.168-1.2 1.9-1.163 1.937.021.02 2.413.034 5.85.03l5.807-.013.013-1.68c.008-1.543.004-1.685-.063-1.736-.055-.038-.697-.05-2.87-.05-1.54 0-2.812-.013-2.824-.034-.009-.017.063-.151.159-.302.1-.152.386-.605.642-1.009.411-.651 1.141-1.802 2.236-3.508.328-.508 1.167-1.798 2.09-3.214.223-.336.453-.71.52-.836l.118-.223V.946H8.168l.545.542Z" 
        fill="#000000"/>
      </g>
    </svg>
  )
}
