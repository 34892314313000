export const MainChainID = 42161
export const urlScan = 'https://arbiscan.io/'
export const contractAddressNodePayment =
  '0xc8AeE16552fB3efe8871947573ef977485Cf2Bab'
// export const contractAddressNodePayment = process.env.REACT_APP_CONTRACT_ADDRESS
//   ? process.env.REACT_APP_CONTRACT_ADDRESS
//   : ethers.ZeroAddress
export const contractAddressTokenPayment =
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'
export const contractAddressReferral =
  '0x23c4E8823639f87d83DebcDc289A7d2C800F99c3'
