import {
  Text,
  Box,
  Spacer,
  Image,
  Badge,
  Link,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  HStack,
  Divider,
  FormControl,
  FormLabel,
  Spinner,
  Grid,
  GridItem,
  ButtonGroup,
  AspectRatio,
  Card,
  CardBody,
  Heading,
} from '@chakra-ui/react'
import { useState, useRef, useContext } from 'react'
import {
  useAccount,
  // useWriteContract,
  // useWaitForTransactionReceipt,
} from 'wagmi'
import { ContractContext } from '../../context/ContractContext'
import { MainChainID } from '../../utils/valueConst'
import { useChainModal } from '@rainbow-me/rainbowkit'
import { notificationToast } from '../../utils/notificationToastify'
import { NodeResponse } from '../../models'
import { useReadContract } from 'wagmi'
import referralABI from '../../contracts/artifacts/contracts/Referral.sol/Referral.json'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ethers } from 'ethers'
import { contractAddressReferral } from '../../utils/valueConst'
import { ExternalLinkIcon } from '@chakra-ui/icons'

function ProjectListPage() {
  const {
    sendTxBuyNode,
    projects,
    isLoadingProjects,
    tokenBalance,
    tokenAllowance,
    sendTxApproveToken,
    decimals,
  } = useContext(ContractContext)
  const { openChainModal } = useChainModal()
  const { chainId } = useAccount()
  // const { data: hash, error, isPending, writeContract } = useWriteContract()

  // const { isLoading: isConfirming, isSuccess: isConfirmed } =
  //   useWaitForTransactionReceipt({
  //     hash,
  //   })

  // const { isConnected } = useAccount()

  // State
  const [isOpenProfile, setIsOpenProfile] = useState(false)
  // const [discounVal, setDiscounVal] = useState(0)
  const [discounPercent, setDiscounPercent] = useState(0)
  const [referralCode, setReferralCode] = useState('')
  const [periodMonth, setPeriodMonth] = useState('1')

  // const [selectedBuyNodeInfo, setSelectedBuyNodeInfo] =
  //   useState<UserNodeResponse>({
  //     address: '',
  //     nodeId: 0,
  //     ownerNodeId: 0,
  //     lastBoughtDate: '',
  //     expiryDate: '',
  //     totalBoughtAmount: 0,
  //     status: '',
  //     publicKey: '',
  //     displayedPrivateKey: '',
  //     metadata: {},
  //     createdAt: '',
  //     updatedAt: '',
  //   })

  const [selectedNodeInfo, setSelectedNodeInfo] = useState<NodeResponse>({
    id: 0,
    title: '',
    subtitle: '',
    logoUrl: '',
    description: '',
    websiteUrl: '',
    price: {
      perMonth: 0,
      per3MonthDiscount: 0,
      per6MonthDiscount: 0,
      per9MonthDiscount: 0,
      per12MonthDiscount: 0,
    },
    slotAvailable: 0,
    numNodeRunning: 0,
    createdAt: '',
    updatedAt: '',
  })

  // Ref
  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const onCloseBuyNodeModal = () => {
    setIsOpenProfile(false)
    setReferralCode('')
    // setDiscounVal(0)
    setDiscounPercent(0)
  }

  const onOpenBuyNodeModal = (data: NodeResponse) => {
    setSelectedNodeInfo(data)
    setIsOpenProfile(true)
  }

  const onPurchase = async (
    _nodeID: number,
    _month: number,
    _referralCode: string,
  ) => {
    notificationToast(sendTxBuyNode(_nodeID, _month, _referralCode))
    setIsOpenProfile(false)
  }

  const onApplyReferralCode = async () => {
    const result = await refetchReferralCode()
    let slotCodeAvailable = Number((result.data as Array<Number>)[2])
    let percentDiscount = Number((result.data as Array<Number>)[3])
    setDiscounPercent(percentDiscount)
    /* 
      Cal discountPrice
      > formula: total*(percentDiscount/100)
      > ex. 300*(10/100)
    */
    // let total = getPrice(String(periodMonth)) * Number(periodMonth)
    // let discountPrice = total * (Number(percentDiscount) / 100)

    // console.log('xxxx=> ', result?.data)
    // console.log('slotCodeAvailable=> ', slotCodeAvailable)
    // console.log('discount=> ', percentDiscount)
    // console.log('Total: ', total)
    // console.log('discount price=> ', discountPrice)

    if (slotCodeAvailable <= 0) {
      toast.error('Cannot use this referral code', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      toast.success('Success apply referral code', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
    // setDiscounVal(discountPrice)
  }

  const onClearReferralCode = () => {
    setReferralCode('')
    // setDiscounVal(0)
    setDiscounPercent(0)
  }

  const {
    // data,
    // isLoading: isReading,
    // error: readError,
    refetch: refetchReferralCode,
  } = useReadContract({
    address: contractAddressReferral,
    abi: referralABI.abi,
    functionName: 'getReferralCodeDetails',
    args: [referralCode],
  })

  const getPrice = (periodMonth: string) => {
    let result = 0
    switch (periodMonth) {
      case '1': {
        result = Number(
          ethers.formatUnits(String(selectedNodeInfo.price.perMonth), decimals),
        )
        break
      }
      case '3': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per3MonthDiscount)) /
          100
        break
      }
      case '6': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per6MonthDiscount)) /
          100
        break
      }
      case '9': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per9MonthDiscount)) /
          100
        break
      }
      case '12': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per12MonthDiscount)) /
          100
        break
      }
      default: {
        result = 0
        break
      }
    }
    return result
  }

  const formattedNumber = (num: any) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
      style: 'decimal',
    }).format(num)
  }
  return (
    <Box>
      <Text fontSize="3xl">Dashboard</Text>
      <></>
      {/* <Flex flexWrap="wrap"> */}
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {isLoadingProjects ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          projects.map((proj) => (
            <div key={proj.id}>
              <Link
                _hover={{ textDecoration: 'none' }}
                onClick={() => onOpenBuyNodeModal(proj)}
              >
                <Box
                  bg="bg.surface"
                  boxShadow="sm"
                  borderRadius="lg"
                  p={{ base: '4', md: '6' }}
                  maxW="sm"
                  borderWidth="1px"
                  overflow="hidden"
                  m={2}
                  position="relative"
                  onClick={() => onOpenBuyNodeModal(proj)}
                >
                  <AspectRatio ratio={16 / 9}>
                    <Image src={proj.logoUrl} alt={proj.title} />
                  </AspectRatio>

                  {/* <Box p="6">
                  <Box display="flex" alignItems="baseline">
                    <Badge borderRadius="full" px="2" colorScheme="teal">
                      New
                    </Badge>
                  </Box>

                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    noOfLines={1}
                  >
                    {proj.title}
                  </Box>

                  <Box>
                    <Box as="span" color="gray.600" fontSize="sm">
                      {proj.description}
                    </Box>
                  </Box>
                  <Box>
                    <Box as="span" color="gray.600" fontSize="sm">
                      <Link href={proj.websiteUrl} isExternal>
                        Official website <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Box>
                  </Box>
                </Box> */}

                  {/* {isConnected && (
                  <Button
                    position="absolute"
                    bottom={3}
                    right={8}
                    colorScheme="blue"
                    onClick={() => onOpenBuyNodeModal(proj)}
                  >
                    Buy Node
                  </Button>
                )} */}
                </Box>
              </Link>

              <Spacer />
            </div>
          ))
        )}

        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          onClose={onCloseBuyNodeModal}
          isOpen={isOpenProfile}
          isCentered
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="center">
              <Text fontSize="2xl" as="i">
                {selectedNodeInfo?.title}
              </Text>
            </ModalHeader>
            <ModalCloseButton onClick={onCloseBuyNodeModal} />
            <ModalBody>
              <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow="hidden"
                variant="outline"
              >
                <Image
                  objectFit="cover"
                  maxW={{ base: '100%', sm: '200px' }}
                  src={selectedNodeInfo.logoUrl}
                  alt={selectedNodeInfo.title}
                />

                <Stack>
                  <CardBody>
                    <Heading size="md">
                      <Link
                        href={selectedNodeInfo.websiteUrl}
                        isExternal
                        _hover={{ textDecoration: 'none' }}
                      >
                        {selectedNodeInfo.title}
                      </Link>
                      &nbsp;
                      <Badge>{selectedNodeInfo.subtitle}</Badge>
                    </Heading>
                    <Text py="2">{selectedNodeInfo.description}</Text>
                  </CardBody>
                </Stack>
              </Card>
              <br />
              <HStack spacing={20} mb={5}>
                <FormControl>
                  <Text as="b">Price (USDT)</Text>
                </FormControl>
                <FormControl>
                  <Text>
                    $
                    {selectedNodeInfo?.price
                      ? formattedNumber(
                          Number(getPrice(String(periodMonth))).toFixed(4),
                        )
                      : 'n/a'}{' '}
                    / Month
                  </Text>
                </FormControl>
              </HStack>
              <HStack spacing={20} mb={5}>
                <FormControl>
                  <Text as="b">Total</Text>
                </FormControl>
                <FormControl>
                  <Text fontSize="2xl" as="ins" fontWeight="bold">
                    $
                    {selectedNodeInfo?.price
                      ? formattedNumber(
                          (
                            (getPrice(String(periodMonth)) *
                              Number(periodMonth) *
                              (100 - discounPercent)) /
                            100
                          ).toFixed(4),
                        )
                      : 'n/a'}
                  </Text>
                </FormControl>
              </HStack>
              <HStack spacing={20} mb={5}>
                <Text as="sup" color="gray">
                  (Price * Month) * discount code = Total
                </Text>
              </HStack>
              <HStack spacing={20} mb={5}>
                <Text as="sup" color="gray">
                  ({formattedNumber(getPrice(String(periodMonth)))} *{' '}
                  {Number(periodMonth)}) * {(100 - discounPercent) / 100} ={' '}
                  {'$' +
                    formattedNumber(
                      (
                        (getPrice(String(periodMonth)) *
                          Number(periodMonth) *
                          (100 - discounPercent)) /
                        100
                      ).toFixed(4),
                    )}
                </Text>
              </HStack>

              <Divider mb={5} />

              {/* <Stack spacing={3} mb={5}>
                <FormControl>
                  <FormLabel>More Details</FormLabel>
                  <Grid templateColumns="repeat(8, 1fr)" gap={4}>
                    <GridItem colSpan={4} h="10">
                      <Input
                        placeholder="FID Farcaster"
                        size="sm"
                      />
                    </GridItem>
                  </Grid>
                </FormControl>
              </Stack> */}
              <Stack spacing={3} mb={5}>
                <FormControl>
                  <FormLabel>Referral Code (optional)</FormLabel>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                    <GridItem colSpan={4} h="10">
                      <Input
                        placeholder="Referral Code"
                        size="sm"
                        ref={initialRef}
                        value={referralCode}
                        onChange={(e) => setReferralCode(e.target.value)}
                      />
                    </GridItem>
                    <GridItem colStart={5} colEnd={6} h="10">
                      <ButtonGroup>
                        {referralCode && (
                          <Button onClick={() => onClearReferralCode()}>
                            Clear
                          </Button>
                        )}
                        <Button
                          isDisabled={!referralCode}
                          onClick={() => onApplyReferralCode()}
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </GridItem>
                  </Grid>
                </FormControl>
                <FormControl>
                  <FormLabel>Period (Months)</FormLabel>
                  <ButtonGroup isAttached>
                    <Button
                      variant={periodMonth === '1' ? 'solid' : 'outline'}
                      color={periodMonth === '1' ? 'white' : 'black'}
                      onClick={() => setPeriodMonth('1')}
                    >
                      1
                    </Button>
                    <Button
                      variant={periodMonth === '3' ? 'solid' : 'outline'}
                      color={periodMonth === '3' ? 'white' : 'black'}
                      onClick={() => setPeriodMonth('3')}
                    >
                      3
                    </Button>
                    <Button
                      variant={periodMonth === '6' ? 'solid' : 'outline'}
                      color={periodMonth === '6' ? 'white' : 'black'}
                      onClick={() => setPeriodMonth('6')}
                    >
                      6
                    </Button>
                    <Button
                      variant={periodMonth === '9' ? 'solid' : 'outline'}
                      color={periodMonth === '9' ? 'white' : 'black'}
                      onClick={() => setPeriodMonth('9')}
                    >
                      9
                    </Button>
                    <Button
                      variant={periodMonth === '12' ? 'solid' : 'outline'}
                      color={periodMonth === '12' ? 'white' : 'black'}
                      onClick={() => setPeriodMonth('12')}
                    >
                      12
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Stack>
              <HStack>
                <Text as="sup" color="gray">
                  <Link
                    href="https://nzlab.co/"
                    isExternal
                    _hover={{ textDecoration: 'none' }}
                  >
                    *Click here to view the instruction{' '}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Text>
              </HStack>
            </ModalBody>
            <ModalFooter>
              {chainId === MainChainID ? (
                tokenAllowance >=
                ethers.parseUnits(
                  (
                    (getPrice(String(periodMonth)) *
                      Number(periodMonth) *
                      (100 - discounPercent)) /
                    100
                  ).toFixed(4),
                  6,
                ) ? (
                  tokenBalance >
                  (getPrice(String(periodMonth)) *
                    Number(periodMonth) *
                    (100 - discounPercent)) /
                    100 ? (
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={() =>
                        onPurchase(
                          Number(selectedNodeInfo?.id),
                          Number(periodMonth),
                          referralCode,
                        )
                      }
                    >
                      Purchase
                    </Button>
                  ) : (
                    <Button colorScheme="gray" mr={3}>
                      Insufficient funds
                    </Button>
                  )
                ) : (
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() =>
                      notificationToast(
                        sendTxApproveToken(
                          (getPrice(String(periodMonth)) *
                            Number(periodMonth) *
                            (100 - discounPercent)) /
                            100,
                        ),
                      )
                    }
                  >
                    Approve token
                  </Button>
                )
              ) : (
                <Button colorScheme="red" mr={3} onClick={openChainModal}>
                  Wrong network
                </Button>
              )}

              <Button onClick={onCloseBuyNodeModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Grid>
    </Box>
  )
}

export default ProjectListPage
