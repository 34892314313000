import { toast } from 'react-toastify'
import { shortenAddress } from './shortenAddress'
import { urlScan } from './valueConst'
import { filterErrorMessage } from './filterErrorMessage'
export function notificationToast(myFunction: any) {
  toast.promise(myFunction, {
    pending: {
      style: {
        boxShadow: '0 4px 30px rgba(0, 0, 0, 1)',
        color: 'white',
        overflow: 'auto',
        fontSize: 12,
        borderWidth: '1px',
        borderColor: '#4b5563',
      },
      render() {
        return (
          <div>
            <h1 className="font-semibold">Processing transaction . . .</h1>
          </div>
        )
      },
    },
    success: {
      autoClose: 5000,
      style: {
        boxShadow: '0 4px 30px rgba(0, 0, 0, 1)',
        color: 'white',
        overflow: 'auto',
        fontSize: 12,
        borderWidth: '1px',
        borderColor: '#4b5563',
      },
      render({ data }: any) {
        return (
          <div>
            <h1 className="font-semibold ">Transaction receipt</h1>
            <a
              className="text-[#6f6e84] hover:text-white font-body text-xs underline mt-5"
              href={`${urlScan}/tx/${data}`}
              target="_blank"
              rel="noreferrer"
            >
              View on etherscan: {shortenAddress(data)}
            </a>
          </div>
        )
      },
    },
    error: {
      autoClose: 3000,
      style: {
        backgroundColor: '#ffffff',
        color: 'black',
        overflow: 'auto',
        fontSize: 12,
        fontWeight: 'bold',
        borderWidth: '1px',
        borderColor: '#000000',
      },
      render({ data }: any) {
        return (
          <div>
            <h1 className="">Transaction Fail</h1>
            <p> {filterErrorMessage(data.message)}</p>
          </div>
        )
      },
    },
  })
}
