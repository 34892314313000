import {
  Text,
  Box,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  Divider,
} from '@chakra-ui/react'
import {  useContext } from 'react'
import { ethers } from 'ethers'
import moment from 'moment'
import { ContractContext } from '../../context/ContractContext'
import { urlScan } from '../../utils/valueConst'
import { shortenAddress } from '../../utils/shortenAddress'

function HistoryPage() {
  const { transactions, projects, isLoadingTransactions, decimals } =
    useContext(ContractContext)

  const getLinkExplorer = (txHash: string) => {
    return String(urlScan) + 'tx/' + txHash
  }

  const getNodeName = (nodeId: number) => {
    let nodeDetail = projects?.find((i) => i.id === nodeId)
    let result = ''
    if (nodeDetail) {
      result = nodeDetail.title
    }

    return result
  }

  return (
    <div>
      <Box>
        <Text fontSize="3xl">Buy Transaction</Text>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Node Name</Th>
                <Th>Block Number</Th>
                <Th>Tx Hash</Th>
                <Th>Referral Code</Th>
                <Th>Total Price</Th>
                {/* <Th>OwnerNodeId</Th> */}
                <Th>Month</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoadingTransactions ? (
                <div> loading transactions . . . </div>
              ) : transactions?.buyNodeTxs ? (
                transactions?.buyNodeTxs?.map((row, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{getNodeName(row.nodeId)}</Td>
                    <Td>{row.blockNumber}</Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        variant="link"
                        onClick={() =>
                          window.open(
                            getLinkExplorer(row.transactionHash),
                            '_blank',
                            'noopener',
                          )
                        }
                      >
                        {shortenAddress(row.transactionHash)}
                      </Button>
                    </Td>
                    <Td>{row.referralCode}</Td>
                    <Td>
                      {Number(
                        ethers.formatUnits(String(row.totalPrice), decimals),
                      )}{' '}
                      USDT
                    </Td>
                    {/* <Td>{row.ownerNodeId}</Td> */}
                    <Td>{row.month}</Td>
                    <Td>{moment(row.date).format('DD/MMM/YYYY')}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={7}>No Data</Td>
                </Tr>
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>#</Th>
                <Th>Node Name</Th>
                <Th>Block Number</Th>
                <Th>Tx Hash</Th>
                <Th>Referral Code</Th>
                <Th>Total Price</Th>
                <Th>Month</Th>
                <Th>Date</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>

      <br />
      <Divider orientation="horizontal" />
      <br />

      <Box>
        <Text fontSize="3xl">Renew Transaction</Text>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Node Name</Th>
                <Th>Block Number</Th>
                <Th>Tx Hash</Th>
                <Th>Referral Code</Th>
                <Th>Total Price</Th>
                {/* <Th>OwnerNodeId</Th> */}
                <Th>Month</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoadingTransactions ? (
                <div> loading transactions . . . </div>
              ) : transactions?.buySubscriptionTxs ? (
                transactions?.buySubscriptionTxs?.map((row, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{getNodeName(row.nodeId)}</Td>
                    <Td>{row.blockNumber}</Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        variant="link"
                        onClick={() =>
                          window.open(
                            getLinkExplorer(row.transactionHash),
                            '_blank',
                            'noopener',
                          )
                        }
                      >
                        {shortenAddress(row.transactionHash)}
                      </Button>
                    </Td>
                    <Td>{row.referralCode}</Td>
                    <Td>
                      {Number(
                        ethers.formatUnits(String(row.totalPrice), decimals),
                      )}{' '}
                      USDT
                    </Td>
                    {/* <Td>{row.ownerNodeId}</Td> */}
                    <Td>{row.month}</Td>
                    <Td>{moment(row.date).format('DD/MMM/YYYY')}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={7}>No Data</Td>
                </Tr>
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>#</Th>
                <Th>Node Name</Th>
                <Th>Block Number</Th>
                <Th>Tx Hash</Th>
                <Th>Referral Code</Th>
                <Th>Total Price</Th>
                <Th>Month</Th>
                <Th>Date</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>
    </div>
  )
}

export default HistoryPage
