import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { Logo } from './Logo'

export const LogoWithText = (props: FlexProps) => {
  return (
    <Flex {...props} alignItems="center">
      <Box
      >
        <Logo />
      </Box>
      <Box
        fontWeight="bold"
        fontSize={{ base: 'body', lg: 'caption-1', xl: 'body' }}
      >
        NZ RESEARCH
      </Box>
    </Flex>
  )
}
