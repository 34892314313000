import React from 'react'
import { Outlet } from 'react-router-dom'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { Flex, Stack, Container, Button, ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Grid,
  Box,
  List, 
  ListItem,
  IconButton,
  Link
 } from '@chakra-ui/react'
import { FiClock, FiGrid, FiPieChart } from 'react-icons/fi'
import { LogoWithText } from './LogoWithText'
import { Logo } from './Logo'
import { SidebarButton } from './SidebarButton'
import { HamburgerIcon, ChevronDownIcon, InfoIcon, QuestionIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { Link as ChakraLink } from '@chakra-ui/react'
import {
  NavLink as RouterLink, // <-- import the NavLink component
} from 'react-router-dom'
import { useContext } from 'react'
import { ContractContext } from '../context/ContractContext'
import { FaDiscord, FaMedium } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

export default function Layout() {
  const { isLogin, walletLogin } = useContext(ContractContext)
  const { 
    isOpen : isOpenPrivacyPolicyModal, 
    onOpen: onOpenPrivacyPolicyModal, 
    onClose: onClosePrivacyPolicyModal 
  } = useDisclosure()
  const { 
    isOpen : isOpenTermsModal, 
    onOpen: onOpenTermsModal, 
    onClose: onCloseTermsModal 
  } = useDisclosure()
  const { 
    isOpen : isOpenFaqModal, 
    onOpen: onOpenFaqModal, 
    onClose: onCloseFaqModal 
  } = useDisclosure()

  const btnFaqRef = React.useRef(null)
  const btnTermsRef = React.useRef(null)
  const btnPrivacyPolicyRef = React.useRef(null)

  return (
    <Flex as="section" minH="100vh">
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading'
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated')

          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      position="absolute"
                      right={10}
                      top={5}
                      colorScheme="blue"
                      onClick={openConnectModal}
                    >
                      Connect Wallet
                    </Button>
                  )
                }

                if (chain.unsupported) {
                  return (
                    <Button
                      position="absolute"
                      right={10}
                      top={5}
                      colorScheme="red"
                      onClick={openChainModal}
                    >
                      Wrong network
                    </Button>
                  )
                }

                if (!isLogin) {
                  return (
                    <Button
                      position="absolute"
                      right={10}
                      top={5}
                      colorScheme="blue"
                      onClick={walletLogin}
                    >
                      Login
                    </Button>
                  )
                }

                return (
                  <div>
                    <ButtonGroup
                      variant="outline"
                      spacing="4"
                      position="absolute"
                      right={10}
                      top={5}
                    >
                      <Button
                        rightIcon={<ChevronDownIcon />}
                        onClick={openChainModal}
                      >
                        {chain.hasIcon && (
                          <div
                            style={{
                              background: chain.iconBackground,
                              width: 12,
                              height: 12,
                              borderRadius: 999,
                              overflow: 'hidden',
                              marginRight: 4,
                            }}
                          >
                            {chain.iconUrl && (
                              <img
                                alt={chain.name ?? 'Chain icon'}
                                src={chain.iconUrl}
                                style={{ width: 12, height: 12 }}
                              />
                            )}
                          </div>
                        )}
                        {chain.name}
                      </Button>

                      <Button
                        onClick={openAccountModal}
                        rightIcon={<HamburgerIcon />}
                      >
                        {account.displayName}
                      </Button>
                    </ButtonGroup>
                  </div>
                )
              })()}
            </div>
          )
        }}
      </ConnectButton.Custom>

      <Stack
        flex="1"
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
        bg="bg.surface"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="5">
          {/* <Logo /> */}
          <LogoWithText></LogoWithText>
          <Stack spacing="1">
            <SidebarButton leftIcon={<FiGrid />}>
              <ChakraLink as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
                Dashboard
              </ChakraLink>
            </SidebarButton>
            
            {/* isLogin */}
            {/* {(() => {
              if (isLogin){
                  return (
                    <> */}
                      <SidebarButton leftIcon={<FiPieChart />}>
                        <ChakraLink as={RouterLink} to="/my_nodes" _hover={{ textDecoration: "none" }}>
                          My Nodes
                        </ChakraLink>
                      </SidebarButton>
                      <SidebarButton leftIcon={<FiClock />}>
                        <ChakraLink as={RouterLink} to="/history" _hover={{ textDecoration: "none" }}>
                          History
                        </ChakraLink>
                      </SidebarButton>
                    {/* </>
                  )
              }
              return null;
            })()} */}
            <SidebarButton leftIcon={<PhoneIcon />}>
              <Link href='https://discord.gg/R4tv7fth' isExternal _hover={{ textDecoration: "none" }}>
                Support
              </Link>
            </SidebarButton>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={0}>
          <Box>
            <Button 
              ref={btnFaqRef} 
              leftIcon={<QuestionIcon boxSize={4} />}
              onClick={onOpenFaqModal} 
              variant="tertiary"
              justifyContent="start"
              iconSpacing="3"
              >
                FAQs
            </Button>
          </Box>
          <Box>
            <Button 
              ref={btnTermsRef} 
              leftIcon={<InfoIcon boxSize={4} />}
              onClick={onOpenTermsModal} 
              variant="tertiary"
              justifyContent="start"
              iconSpacing="3"
              >
                Terms
            </Button>
          </Box>
          <Box>
            <Button 
                ref={btnPrivacyPolicyRef} 
                onClick={onOpenPrivacyPolicyModal} 
                leftIcon={<InfoIcon boxSize={4} />}
                variant="tertiary"
                justifyContent="start"
                iconSpacing="3"
                >
                  Privacy Policy
              </Button>
          </Box>
          <Box textAlign={{ base: 'center' }} mt={4}>
            <ButtonGroup variant='outline' spacing='6'>
              <IconButton
                as={Link}
                variant='outline'
                isRound={true}
                colorScheme='teal'
                size='xs'
                aria-label='Discord'
                href='https://discord.gg/R4tv7fth'
                target='_black'
                icon={<FaDiscord />}
              />
              <IconButton
                as={Link}
                variant='outline'
                isRound={true}
                colorScheme='teal'
                size='xs'
                aria-label='X'
                href='https://twitter.com/nz_research'
                target='_black'
                icon={<FaXTwitter />}
              />
              <IconButton
                as={Link}
                variant='outline'
                isRound={true}
                colorScheme='teal'
                size='xs'
                aria-label='Medium'
                href='https://medium.com/@nz_research'
                target='_black'
                icon={<FaMedium />}
              />
              <IconButton
                as={Link}
                variant='outline'
                isRound={true}
                colorScheme='teal'
                size='xs'
                aria-label='Email'
                href='mailto:team.nzresearch@gmail.com'
                icon={<EmailIcon />}
              />
            </ButtonGroup>
          </Box>
        </Stack>
      </Stack>
      <Container padding="10">
        <Outlet />
      </Container>

      {/* FAQs */}
      <Modal
        onClose={onCloseFaqModal}
        finalFocusRef={btnFaqRef}
        isOpen={isOpenFaqModal}
        scrollBehavior="inside"
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
          <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            <Box  w='100%' p={2} ><Logo /></Box>
            <Box 
              w='100%' 
              p={2} 
              textAlign={{ base: 'center' }}
              top={0}
              right={0}
              left={0}
              bottom={0}
              margin="auto"
            >

              <Text fontSize='2xl' as='u'>FAQs</Text>
              
            </Box>
          </Grid>

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as='b'>1. What is NZLAB?</Text>
            <br />
            <Text fontSize='sm'>
              NZLAB is a comprehensive service provider that simplifies participation in the crypto space. 
            </Text>
            <br />
           
            <Text as='b'>2. What does NZLAB offer, especially in terms of validator services?</Text>
            <Text fontSize='sm'>
              NZLAB offers hassle-free setup and management of validator nodes for various blockchain projects. Our services make it easy for users to operate and maintain validator nodes without dealing with complex technical details.
            </Text>
            <br />

            
            <Text as='b'>3. How can users purchase services with NZLAB using a web3 account on the Arbitrum chain?</Text>
            <Text fontSize='sm'>
              Users can purchase NZLAB services by connecting their web3 account on the Arbitrum blockchain. 
            </Text>
            <br />

           

            <Text as='b'>4. What payment methods are accepted by NZLAB?</Text>
            <Text fontSize='sm'>
              NZLAB accepts payments in USDT (Tether) on the Arbitrum network. 
            </Text>
            <br />

            <Text as='b'>5. How can I monitor my node?</Text>
            <Text fontSize='sm'>
              NZ Research cannot guarantee the receipt of any airdrops or similar rewards through its service. Participation in any airdrop is subject to the rules and eligibility criteria set by the respective blockchain.
            </Text>
            <br />

            <Text as='b'>6. How can users ensure the security of their private key?</Text>
            <Text fontSize='sm'>
            NZLAB encrypts users' private keys, which can be accessed from the dashboard under the "Key" icon. Users should store their keys in a safe place.
            </Text>
            <br />

          </ModalBody>
          <ModalFooter>
            <Flex justify="center" width="100%">
              <Button colorScheme="cyan" onClick={onCloseFaqModal} variant='outline'>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Terms */}
      <Modal
        onClose={onCloseTermsModal}
        finalFocusRef={btnTermsRef}
        isOpen={isOpenTermsModal}
        scrollBehavior="inside"
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
          <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            <Box  w='100%' p={2} ><Logo /></Box>
            <Box 
              w='100%' 
              p={2} 
              textAlign={{ base: 'center' }}
              top={0}
              right={0}
              left={0}
              bottom={0}
              margin="auto"
            >

              <Text fontSize='2xl' as='u'>Terms & Conditions</Text>
              
            </Box>
          </Grid>

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as='b'>1. Introduction</Text>
            <br />
            <Text fontSize='sm'>
              Welcome to NZ Research These Terms and Conditions (“Terms”) govern your use of the NZ Research. By using our services, you agree to be bound by these Terms.
            </Text>
            <br />
           
            <Text as='b'>2. Service Description</Text>
            <Text fontSize='sm'>
              NZ Research provides a platform for the deployment of blockchain nodes. This service manages installations, updates, and provides our customers with a user-friendly dashboard.
            </Text>
            <br />

            
            <Text as='b'>3. User Obligations</Text>
            <Text fontSize='sm'>
            Please ensure that you provide accurate and up-to-date information during the sign-up process, and remember to keep this information current. It is crucial for users to maintain the confidentiality of their account information and to take responsibility for all activities under their account.
            </Text>
            <br />

           

            <Text as='b'>4. Usage Restrictions</Text>
            <Text fontSize='sm'>
              The service should not be utilized for any illegal or unauthorized purposes. Users are prohibited from engaging in any activity that disrupts or damages the performance and functionality of the service.
            </Text>
            <br />

            <Text as='b'>5. No Guarantee of Airdrops</Text>
            <Text fontSize='sm'>
              NZ Research cannot guarantee the receipt of any airdrops or similar rewards through its service. Participation in any airdrop is subject to the rules and eligibility criteria set by the respective blockchain.
            </Text>
            <br />

            <Text as='b'>6. Privacy Policy</Text>
            <Text fontSize='sm'>
              By using NZ Research, you’re consent to the collection, use, and disclosure of your information in accordance with our Privacy Policy.
            </Text>
            <br />

            <Text as='b'>7. Limitation of Liability</Text>
              <List spacing={2}>
                <ListItem pl={3}>
                7.1 NZ Research is not responsible for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the service.
                </ListItem>
                <ListItem pl={3}>
                7.2 NZ Research is not liable for any losses resulting from market fluctuations or the inherent risks of cryptocurrency investments.
                </ListItem>
                <ListItem pl={3}>
                7.3 Network congestion, Sybil attacks, and other blockchain-related issues may occur. We are not responsible for any problems or associated losses due to blockchain network anomalies.
                </ListItem>
                <ListItem pl={3}>
                7.4 NZ Research provides information related to validator nodes. Any actions, such as transactions, carried out outside of our portal, particularly through external wallets or platforms, are the sole responsibility of the user. NZ Research is not accountable for any outcomes or issues that arise from such external actions.
                </ListItem>
              </List>
            <br />

            <Text as='b'>8. Subscription Plan Changes</Text>
            <Text fontSize='sm'>
            When a user upgrades their subscription plan, the change will take effect after the current subscription period ends. The new plan will start at the conclusion of the ongoing subscription term.
            </Text>
            <br />

            <Text as='b'>9. Refund Policy</Text>
            <Text fontSize='sm'>
            Refunds will be issued only for the remaining period of the user's subscription, excluding any additional fees or charges incurred. The refunds will be processed in USDT on Arbitrum Mainnet and will be calculated based on the USDT/USD exchange rate at the time of the refund.
            </Text>
            <br />

            <Text as='b'>10. Service Availability</Text>
            <Text fontSize='sm'>
            At NZ Research, we strive to provide reliable service, but we cannot guarantee uninterrupted or error-free operation. At times, we may need to temporarily suspend access for maintenance or updates to ensure the best possible service for our users.
            </Text>
            <br />

            <Text as='b'>11. Changes to Terms</Text>
            <Text fontSize='sm'>
            NZ Research has the right to modify these terms at any time. Your continued use of the service after any changes indicates your acceptance of the new terms.
            </Text>
            <br />

            <Text as='b'>12. Termination</Text>
            <Text fontSize='sm'>
            At NZ Research, we reserve the right to promptly terminate or suspend access to our services for any behavior that violates our Terms, poses harm to other users, ourselves, or third parties, or for any other reason we deem necessary.
            </Text>
            <br />

            <Text as='b'>13. Governing Law</Text>
            <Text fontSize='sm'>
            These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which NZ Research operates.
            </Text>
            <br />

            <Text as='b'>14. Contact Information</Text>
            <Text fontSize='sm'>
              For any question or concerns regarding these terms, please contact us at sale.nzresearch@gmail.com. 
            </Text>
            <br />


            <Text fontSize='sm' as='i'>
              By using NZ Research, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree with any part of these Terms, you should not use our platform.
            </Text>

          </ModalBody>
          <ModalFooter>
            <Flex justify="center" width="100%">
              <Button colorScheme="cyan" onClick={onCloseTermsModal} variant='outline'>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Privacy Policy */}
      <Modal
        onClose={onClosePrivacyPolicyModal}
        finalFocusRef={btnPrivacyPolicyRef}
        isOpen={isOpenPrivacyPolicyModal}
        scrollBehavior="inside"
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
          <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            <Box  w='100%' p={2} ><Logo /></Box>
            <Box 
              w='100%' 
              p={2} 
              textAlign={{ base: 'center' }}
              top={0}
              right={0}
              left={0}
              bottom={0}
              margin="auto"
            >

              <Text fontSize='2xl' as='u'>Privacy Policy</Text>
              
            </Box>
          </Grid>

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as='b'>Introduction</Text>
            <br />
            <Text fontSize='sm'>
              Welcome to NZ Research, where safeguarding your privacy and security is our top priority. Our Privacy Policy provides a clear understanding of how we collect, utilize, and share information when you use our one-click node deployment service.
            </Text>
            <br />
           
            <Text as='b'>Information Collection</Text>
            <Text fontSize='sm'>
              We want to assure you that we do not collect any personal information during the sign-up or sign-in process. However, we do gather technical information on how you engage with our service, such as login times and platform activities. Your privacy and security are our top priorities.
            </Text>
            <br />

            
            <Text as='b'>Use of Information</Text>
            <Text fontSize='sm'>
              We are committed to providing, maintaining, and enhancing our service to better meet your needs. We ensure clear and timely communication about your account and service updates. Our goal is to elevate your experience and customize the service to suit your preferences. Additionally, we are here to provide dedicated customer support and promptly address your inquiries.
            </Text>
            <br />

           

            <Text as='b'>Sharing of Information</Text>
            <Text fontSize='sm'>
              Rest assured, your personal information is safe with us. We never sell, rent, or trade your data with third parties, and we only share it with trusted service providers who assist us in delivering top-notch services. In addition, we may disclose information if required by law or in response to legal requests.
            </Text>
            <br />

            <Text as='b'>Data Security</Text>
            <Text fontSize='sm'>
              We have security measures in place to protect your information from unauthorized access, alteration, or destruction. However, please note that no method of transmission over the Internet or electronic storage is completely secure.
            </Text>
            <br />

            <Text as='b'>Cookies</Text>
            <Text fontSize='sm'>
              Our service may use cookies to enhance your experience. You have the option to accept or refuse these cookies.
            </Text>
            <br />

            <Text as='b'>Your Rights</Text>
            <Text fontSize='sm'>
              Take control of your personal information. You have the power to access, update, or delete your data. Plus, you can choose to opt out of receiving marketing communications.
            </Text>
            <br />

            <Text as='b'>Changes to the Privacy Policy</Text>
            <Text fontSize='sm'>
              We may update this Privacy Policy and will notify you of any changes by posting the new policy on this page.
            </Text>
            <br />

            <Text as='b'>Contact Us</Text>
            <Text fontSize='sm'>
              For any questions about this Privacy Policy, please contact us at team.nzresearch@gmail.com By using NZ Research products, you agree to the collection and use of information in accordance with this policy.
            </Text>
            <br />

          </ModalBody>
          <ModalFooter>
            <Flex justify="center" width="100%">
              <Button colorScheme="cyan" onClick={onClosePrivacyPolicyModal} variant='outline'>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
