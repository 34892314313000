import '@rainbow-me/rainbowkit/styles.css'
import './polyfills'
import './index.css'
import React from 'react'
import { RouterProvider } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { theme } from '@chakra-ui/pro-theme'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import reportWebVitals from './reportWebVitals'
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { WagmiProvider } from 'wagmi'
import router from './routes/root'
import axios from 'axios'
import { ContractProvider } from './context/ContractContext'
import { arbitrum, sepolia } from 'wagmi/chains'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
const proTheme = extendTheme(theme)
const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.teal },
}
const myTheme = extendTheme(extenstion, proTheme)

const config = getDefaultConfig({
  appName: 'NZ NODE',
  projectId: 'YOUR_PROJECT_ID',
  chains: [
    arbitrum,
    ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [sepolia] : []),
  ],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()

root.render(
  <WagmiProvider config={config}>
    <ContractProvider>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <ChakraProvider theme={myTheme}>
              <RouterProvider router={router} />
            </ChakraProvider>
            <ToastContainer
              position="top-right"
              className="!top-20"
              theme="dark"
              hideProgressBar={false}
            />
          </RainbowKitProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ContractProvider>
  </WagmiProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
