import { createBrowserRouter } from 'react-router-dom'
import DashboardPage from '../pages/Dashboard'
import MyNodesPage from '../pages/MyNodes'
import HistoryPage from '../pages/History'
// import ErrorPage from '../pages/Error'
// import App from '../App'
import Layout from "../components/Layout"

const router = createBrowserRouter([

  {
    // parent route component
    element: <Layout />,
    // child route components
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/my_nodes",
        element: <MyNodesPage />,
      },
      {
        path: "/history",
        element: <HistoryPage />,
      },
    ],
  },


  // {
  //   path: '/',
  //   element: <App />,
  // },
  // {
  //   path: '/dashboard',
  //   element: <DashboardPage />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: '/projects',
  //   element: <ProjectPage />,
  //   errorElement: <ErrorPage />,
  // },
])

export default router
