import React, { createContext, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { NodePayment, Token } from '../contracts/typechain-types'
import artifactNodePayment from '../contracts/artifacts/contracts/NodePayment.sol/NodePayment.json'
import artifactToken from '../contracts/artifacts/contracts/Token.sol/Token.json'
import { useAccount } from 'wagmi'
import axios from 'axios'
import {
  NodeResponse,
  TransactionsResponse,
  // UserResponse,
  UserNodeResponse,
} from '../models'
import {
  contractAddressNodePayment,
  contractAddressTokenPayment,
} from '../utils/valueConst'

interface IContract {
  sendTxBuyNode: (
    _nodeID: number,
    _month: number,
    _referralCode: string,
  ) => Promise<string>
  sendTxBuySubscription: (
    _nodeID: number,
    _month: number,
    _currentOwnerNodeID: number,
    _referralCode: string,
  ) => Promise<string>
  projects: NodeResponse[]
  isLoadingProjects: boolean
  myNodesAll: UserNodeResponse[]
  myNodesPending: UserNodeResponse[]
  myNodesOnline: UserNodeResponse[]
  myNodesOffline: UserNodeResponse[]
  myNodesExpired: UserNodeResponse[]
  isLoadingMyNodes: boolean
  transactions: TransactionsResponse | undefined
  isLoadingTransactions: boolean
  tokenBalance: number
  tokenAllowance: number
  sendTxApproveToken: (_amount: number) => Promise<string>
  decimals: number
  walletLogin: () => Promise<void>
  isLogin: boolean
}

export const ContractContext = createContext<IContract>({
  sendTxBuyNode: async () => '',
  sendTxBuySubscription: async () => '',
  projects: [],
  isLoadingProjects: false,
  myNodesAll: [],
  myNodesPending: [],
  myNodesOnline: [],
  myNodesOffline: [],
  myNodesExpired: [],
  isLoadingMyNodes: false,
  transactions: undefined,
  isLoadingTransactions: false,
  tokenBalance: 0,
  tokenAllowance: 0,
  sendTxApproveToken: async () => '',
  walletLogin: async () => {},
  decimals: 0,
  isLogin: false,
})

interface ChildrenProps {
  children: React.ReactNode
}

export const ContractProvider = ({ children }: ChildrenProps) => {
  const { address, chainId } = useAccount()
  const contractNodePaymentAddress = contractAddressNodePayment

  let providerWindow: ethers.BrowserProvider
  if (typeof window !== 'undefined') {
    try {
      providerWindow = new ethers.BrowserProvider(window.ethereum as any)
    } catch (error) {}
  }

  const [initialLoading, setInitialLoading] = useState(true)

  // state login
  const [loginKey, setloginKey] = useState('')
  const [isLogin, setIsLogin] = useState(false)

  // state token
  const [tokenBalance, setTokenBalance] = useState(0)
  const [tokenAllowance, setTokenAllowance] = useState(0)
  const [decimals, setDecimals] = useState(6)

  // state Dashboard
  const [projects, setProjects] = useState<NodeResponse[]>([])
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false)

  // state My Nodes
  const [myNodesAll, setMyNodesAll] = useState<UserNodeResponse[]>([])

  const [isLoadingMyNodes, setIsLoadingMyNodes] = useState<boolean>(false)

  const [myNodesPending, setMyNodesPending] = useState<UserNodeResponse[]>([])
  const [myNodesOnline, setMyNodesOnline] = useState<UserNodeResponse[]>([])
  const [myNodesOffline, setMyNodesOffline] = useState<UserNodeResponse[]>([])
  const [myNodesExpired, setMyNodesExpired] = useState<UserNodeResponse[]>([])

  // state History
  const [transactions, setTransactions] = useState<TransactionsResponse>(
    {} as TransactionsResponse,
  )
  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(false)

  useEffect(() => {
    const loadInit = async () => {
      getDataNodeDashboard()

      const loginStatus = await checkLoginKey()

      if (address && loginStatus) {
        getHistoryData()
        getMyNodesData()
        loadDecimalsToken()
        loadBalanceOfToken(address)
        loadAllowanceToken(address)
      } else {
        // clear data
        getMyNodesData()
        setTransactions({} as TransactionsResponse)
        setMyNodesAll([])
        // Mockup For Test
        // setMyNodesAll([
        //   {
        //     address: "string",
        //     nodeId: 0,
        //     ownerNodeId: 5,
        //     lastBoughtDate: "2024-07-30T07:24:37.413Z",
        //     expiryDate: "2024-07-30T07:24:37.413Z",
        //     totalBoughtAmount: 0,
        //     publicKey: "string",
        //     // privateKey: "string",
        //     displayedPrivateKey: "string",
        //     metadata: {},
        //     status: "Pending",
        //     createdAt: "2024-07-30T07:24:37.413Z",
        //     updatedAt: "2024-07-30T07:24:37.413Z"
        //   }])
        setMyNodesPending([])
        setMyNodesOnline([])
        setMyNodesOffline([])
        setMyNodesExpired([])
        loadDecimalsToken()
      }
      setInitialLoading(false)
    }

    loadInit()
  }, [address, chainId, isLogin])

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms))

  const getContractNodePaymentSigner = async () => {
    const signer = await providerWindow.getSigner()
    const contractNodePayment = new ethers.Contract(
      contractNodePaymentAddress,
      artifactNodePayment.abi,
      signer,
    ) as unknown as NodePayment

    return contractNodePayment
  }

  const getTokenContract = (tokenAddress: string) => {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      artifactToken.abi,
      providerWindow,
    ) as unknown as Token

    return tokenContract
  }

  const getTokenContractSigner = async (tokenAddress: string) => {
    const signer = await providerWindow.getSigner()
    const tokenContract = new ethers.Contract(
      tokenAddress,
      artifactToken.abi,
      signer,
    ) as unknown as Token

    return tokenContract
  }

  const checkLoginKey = async () => {
    try {
      const keyBearer = localStorage.getItem('keyusernz')
      const addressUser = localStorage.getItem('addressusernz')

      if (address !== undefined) {
        if (address.toLowerCase() === addressUser?.toLowerCase()) {
          if (keyBearer !== null) {
            const response = await axios.get('/transaction', {
              headers: {
                Authorization: 'Bearer ' + keyBearer,
              },
            })
            if (response.status === 200) {
              setIsLogin(true)
              setloginKey(keyBearer)
              return true
            } else {
              console.error(String(response))
              setIsLogin(false)
              return false
            }
          } else {
            setIsLogin(false)
            return false
          }
        } else {
          setIsLogin(false)
          return false
        }
      } else {
        setIsLogin(false)
        return false
      }
    } catch (err) {
      console.log(err)
      setIsLogin(false)
      return false
    }
  }

  const walletLogin = async () => {
    try {
      if (address === undefined) throw new Error('disconnect wallet')
      const message = 'Please sign this message for login in nz node'
      const timestamp = Date.now() + 60 * 1000
      const fullMessage = `${message} ${timestamp}`
      const signer = await providerWindow.getSigner()
      const signedMessage = await signer.signMessage(fullMessage)
      const response = await axios
        .post(`/auth/wallet-login`, {
          signature: signedMessage,
          timestamp,
        } as any)
        .catch((err) => {
          throw new Error(err)
        })
      if (response.status === 201) {
        localStorage.setItem('keyusernz', response.data)
        localStorage.setItem('addressusernz', address)
        setIsLogin(true)
        setloginKey(response.data)
      } else {
        console.error(String(response))
        setIsLogin(false)
      }
    } catch (err) {
      console.log(err)
      setIsLogin(false)
    }
  }

  const getDataNodeDashboard = async () => {
    try {
      setIsLoadingProjects(true)
      axios.get(`/node`).then((res) => {
        setProjects(res.data)
        delay(1000).then(() => setIsLoadingProjects(false))
      })
    } catch (err) {
      setIsLoadingProjects(false)
      console.log(err)
    }
  }

  const getMyNodesData = async () => {
    try {
      setMyNodesAll([])
      setMyNodesPending([])
      setMyNodesOnline([])
      setMyNodesOffline([])
      setMyNodesExpired([])
      if (isLogin && loginKey.length > 0) {
        setIsLoadingMyNodes(true)
        axios
          .get(`/user`, {
            headers: {
              Authorization: 'Bearer ' + loginKey,
            },
          })
          .then((res) => {
            // let all UserNodeResponse[]

            res.data?.userNodes?.forEach(function (value: UserNodeResponse) {
              // setMyNodesAll([...myNodesAll, value])
              setMyNodesAll((myNodesAll) => [...myNodesAll, value])
              switch (value.status) {
                case 'Pending':
                  setMyNodesPending((myNodesPending) => [
                    ...myNodesPending,
                    value,
                  ])
                  break
                case 'Online':
                  setMyNodesOnline((myNodesOnline) => [...myNodesOnline, value])
                  break
                case 'Offline':
                  setMyNodesOffline((myNodesOffline) => [
                    ...myNodesOffline,
                    value,
                  ])
                  break
                case 'Expired':
                  setMyNodesExpired((myNodesExpired) => [
                    ...myNodesExpired,
                    value,
                  ])
                  break
              }
            })
            delay(1000).then(() => setIsLoadingMyNodes(false))
          })
      }
    } catch (err) {
      setIsLoadingMyNodes(false)
      console.log(err)
    }
  }

  const getHistoryData = async () => {
    try {
      setTransactions({} as TransactionsResponse)
      if (isLogin && loginKey.length > 0) {
        setIsLoadingTransactions(true)
        axios
          .get(`/transaction`, {
            headers: {
              Authorization: 'Bearer ' + loginKey,
            },
          })
          .then((res) => {
            setTransactions(res.data)
            delay(1000).then(() => setIsLoadingTransactions(false))
          })
      }
    } catch (err) {
      setIsLoadingTransactions(false)
      console.log(err)
    }
  }

  const sendTxBuyNode = async (
    _nodeID: number,
    _month: number,
    _referralCode: string,
  ) => {
    try {
      const contract = await getContractNodePaymentSigner()
      const transactionHash = await contract.buyNode(
        _nodeID,
        _month,
        _referralCode,
      )
      await transactionHash.wait()
      if (address) {
        await Promise.all([
          getHistoryData(),
          getMyNodesData(),
          loadBalanceOfToken(address),
          loadAllowanceToken(address),
        ])
      }
      return transactionHash.hash
    } catch (error: any) {
      console.log(error)
      throw new Error(error.reason)
      // throw new Error(error.error.data.message)
    }
  }

  const sendTxBuySubscription = async (
    _nodeID: number,
    _month: number,
    _currentOwnerNodeID: number,
    _referralCode: string,
  ) => {
    try {
      const contract = await getContractNodePaymentSigner()
      const transactionHash = await contract.buySubscription(
        _nodeID,
        _month,
        _currentOwnerNodeID,
        _referralCode,
      )
      await transactionHash.wait()
      if (address) {
        await Promise.all([
          getHistoryData(),
          getMyNodesData(),
          loadBalanceOfToken(address),
          loadAllowanceToken(address),
        ])
      }
      return transactionHash.hash
    } catch (error: any) {
      console.log(error)
      throw new Error(error.reason)
      // throw new Error(error.error.data.message)
    }
  }

  const sendTxApproveToken = async (_amount: number) => {
    try {
      const contractToken = await getTokenContractSigner(
        contractAddressTokenPayment,
      )
      const transactionHash = await contractToken.approve(
        contractAddressNodePayment,
        ethers.parseUnits(String(_amount.toFixed(6)), decimals),
      )
      await transactionHash.wait()
      if (address) {
        await loadAllowanceToken(address)
      }
      return transactionHash.hash
    } catch (error: any) {
      console.log(error)
      throw new Error(error.reason)
      // throw new Error(error.error.data.message)
    }
  }

  const loadBalanceOfToken = async (address: string) => {
    try {
      console.log('address', address)
      const contractToken = getTokenContract(contractAddressTokenPayment)
      const result = await contractToken.balanceOf(address)
      setTokenBalance(Number(result))
      console.log('balance', Number(result))
    } catch (error) {
      console.log(error)
    }
  }

  const loadAllowanceToken = async (address: string) => {
    try {
      console.log('address', address)
      const contractToken = getTokenContract(contractAddressTokenPayment)
      const result = await contractToken.allowance(
        address,
        contractAddressNodePayment,
      )
      setTokenAllowance(Number(result))
      console.log('allownace', Number(result))
    } catch (error) {
      console.log(error)
    }
  }

  const loadDecimalsToken = async () => {
    try {
      const contractToken = getTokenContract(contractAddressTokenPayment)
      const result = await contractToken.decimals()
      setDecimals(Number(result))
      console.log('decimals', Number(result))
    } catch (error) {
      console.log(error)
    }
  }

  // const addlistenerEvents = async () => {
  //   if (!window.ethereum) console.log('Please install metamask')
  //   try {
  //     if (window.ethereum != undefined) {
  //       //@ts-ignore
  //       window.ethereum.on('accountsChanged', () => {
  //         if (address) {
  //           loadBalanceOfToken(address)
  //           loadAllowanceToken(address)
  //         }
  //       })

  //       //@ts-ignore
  //       window.ethereum.on('chainChanged', (_chainId) => {
  //         if (address) {
  //           loadBalanceOfToken(address)
  //           loadAllowanceToken(address)
  //         }
  //       })
  //     }
  //   } catch (error) {}
  // }

  return (
    <ContractContext.Provider
      value={{
        sendTxBuyNode,
        sendTxBuySubscription,
        projects,
        isLoadingProjects,
        myNodesAll,
        myNodesPending,
        myNodesOnline,
        myNodesOffline,
        myNodesExpired,
        isLoadingMyNodes,
        transactions,
        isLoadingTransactions,
        tokenBalance,
        tokenAllowance,
        sendTxApproveToken,
        decimals,
        walletLogin,
        isLogin,
      }}
    >
      {!initialLoading && children}
    </ContractContext.Provider>
  )
}
