import {
  Text,
  Box,
  Flex,
  Spinner,
  Spacer,
  Image,
  Badge,
  Link,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  HStack,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  ButtonGroup,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tag,
  AspectRatio,
  InputGroup,
  InputRightElement,
  IconButton,
  Card,
  CardBody,
  Heading,
} from '@chakra-ui/react'
import { useState, useRef, useContext } from 'react'
import { NodeResponse, UserNodeResponse } from '../../models'
import { ethers } from 'ethers'
import moment from 'moment'
import { useAccount, useReadContract } from 'wagmi'
import { ContractContext } from '../../context/ContractContext'
import { ViewIcon, ViewOffIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { notificationToast } from '../../utils/notificationToastify'
import { toast } from 'react-toastify'
import { MainChainID, contractAddressReferral } from '../../utils/valueConst'
import referralABI from '../../contracts/artifacts/contracts/Referral.sol/Referral.json'
import { useChainModal } from '@rainbow-me/rainbowkit'
import { Icon } from '@chakra-ui/react'
import {
  CheckIcon,
  RepeatClockIcon,
  MinusIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons'

function MyNodesPage() {
  const {
    myNodesAll,
    myNodesPending,
    myNodesOnline,
    myNodesOffline,
    myNodesExpired,
    projects,
    isLoadingMyNodes,
    sendTxBuySubscription,
    tokenAllowance,
    tokenBalance,
    sendTxApproveToken,
    decimals,
  } = useContext(ContractContext)

  const { openChainModal } = useChainModal()
  const { chainId } = useAccount()

  const getNodeDetail = (nodeId: number) => {
    let nodeDetail = projects?.find((i) => i.id === nodeId)
    let result

    if (nodeDetail) {
      result = nodeDetail
    }

    return result
  }

  // State
  const [showPublicKey, setShowPublicKey] = useState(false)
  const [showPrivateKey, setShowPrivateKey] = useState(false)
  const [isOpenNodeDetail, setIsOpenNodeDetail] = useState(false)
  const [isOpenCompleteForm, setIsOpenCompleteForm] = useState(false)
  const [discounPercent, setDiscounPercent] = useState(0)
  const [referralCode, setReferralCode] = useState('')
  const [periodMonth, setPeriodMonth] = useState('1')
  const [currentOwnerNodeID, setCurrentOwnerNodeID] = useState(9999)
  const [selectedBuyNodeInfo, setSelectedBuyNodeInfo] =
    useState<UserNodeResponse>({
      address: '',
      nodeId: 0,
      ownerNodeId: 0,
      lastBoughtDate: '',
      expiryDate: '',
      totalBoughtAmount: 0,
      status: '',
      publicKey: '',
      displayedPrivateKey: '',
      metadata: {},
      createdAt: '',
      updatedAt: '',
    })

  const [selectedNodeInfo, setSelectedNodeInfo] = useState<NodeResponse>({
    id: 0,
    title: '',
    subtitle: '',
    logoUrl: '',
    description: '',
    websiteUrl: '',
    price: {
      perMonth: 0,
      per3MonthDiscount: 0,
      per6MonthDiscount: 0,
      per9MonthDiscount: 0,
      per12MonthDiscount: 0,
    },
    slotAvailable: 0,
    numNodeRunning: 0,
    createdAt: '',
    updatedAt: '',
  })

  // Ref
  const nodeDetailInitialRef = useRef(null)
  const nodeDetailFinalRef = useRef(null)
  const completeFormInitialRef = useRef(null)
  const completeFormFinalRef = useRef(null)

  const onClickShowPublicKey = () => setShowPublicKey(!showPublicKey)
  const onClickShowPrivateKey = () => setShowPrivateKey(!showPrivateKey)

  const onCloseBuyNodeModal = () => {
    setIsOpenNodeDetail(false)
    setReferralCode('')
    // setDiscounVal(0)
    setDiscounPercent(0)
  }

  const onOpenBuyNodeModal = (
    userNodeData: UserNodeResponse,
    nodeData: NodeResponse,
    _currentOwnerNodeID: number,
  ) => {
    setSelectedBuyNodeInfo(userNodeData)
    setSelectedNodeInfo(nodeData)
    setCurrentOwnerNodeID(_currentOwnerNodeID)
    setIsOpenNodeDetail(true)
  }

  const onOpenCompleteFormModal = () => {
    setIsOpenCompleteForm(true)
  }

  const onCloseCompleteFormModal = () => {
    setIsOpenCompleteForm(false)
  }

  const onRenew = async (
    _nodeID: number,
    _month: number,
    _currentOwnerNodeID: number,
    _referralCode: string,
  ) => {
    notificationToast(
      sendTxBuySubscription(
        _nodeID,
        _month,
        _currentOwnerNodeID,
        _referralCode,
      ),
    )
    setIsOpenNodeDetail(false)
  }

  const onApplyReferralCode = async () => {
    const result = await refetchReferralCode()
    let slotCodeAvailable = Number((result.data as Array<Number>)[2])
    let percentDiscount = Number((result.data as Array<Number>)[3])
    setDiscounPercent(percentDiscount)
    /* 
        Cal discountPrice
        > formula: total*(percentDiscount/100)
        > ex. 300*(10/100)
      */
    // let total = getPrice(String(periodMonth)) * Number(periodMonth)
    // let discountPrice = total * (Number(percentDiscount) / 100)

    // console.log('xxxx=> ', result?.data)
    // console.log('slotCodeAvailable=> ', slotCodeAvailable)
    // console.log('discount=> ', percentDiscount)
    // console.log('Total: ', total)
    // console.log('discount price=> ', discountPrice)

    if (slotCodeAvailable <= 0) {
      toast.error('Cannot use this referral code', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      toast.success('Success apply referral code', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
    // setDiscounVal(discountPrice)
  }

  const onClearReferralCode = () => {
    setReferralCode('')
    // setDiscounVal(0)
    setDiscounPercent(0)
  }

  const {
    // data,
    // isLoading: isReading,
    // error: readError,
    refetch: refetchReferralCode,
  } = useReadContract({
    address: contractAddressReferral,
    abi: referralABI.abi,
    functionName: 'getReferralCodeDetails',
    args: [referralCode],
  })

  const getPrice = (periodMonth: string) => {
    let result = 0
    switch (periodMonth) {
      case '1': {
        result = Number(
          ethers.formatUnits(String(selectedNodeInfo.price.perMonth), decimals),
        )
        break
      }
      case '3': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per3MonthDiscount)) /
          100
        break
      }
      case '6': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per6MonthDiscount)) /
          100
        break
      }
      case '9': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per9MonthDiscount)) /
          100
        break
      }
      case '12': {
        result =
          (Number(
            ethers.formatUnits(
              String(selectedNodeInfo.price.perMonth),
              decimals,
            ),
          ) *
            (100 - selectedNodeInfo.price.per12MonthDiscount)) /
          100
        break
      }
      default: {
        result = 0
        break
      }
    }
    // console.log(result)
    return result
  }

  const getStatusColor = (status: string) => {
    let result = ''
    switch (status) {
      case 'Online': {
        result = 'green'
        break
      }
      case 'Pending': {
        result = 'yellow'
        break
      }
      case 'Offline': {
        result = 'red'
        break
      }
      case 'Expired': {
        result = 'red'
        break
      }
    }
    return result
  }

  const formattedNumber = (num: any) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
      style: 'decimal',
    }).format(num)
  }

  return (
    <Box>
      {/* <Text fontSize="3xl">My Nodes</Text> */}

      <Tabs size="md" variant="enclosed" pt={5}>
        <TabList>
          <Tab>
            ALL&nbsp;
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme="cyan"
            >
              {myNodesAll.length}
            </Tag>
          </Tab>
          <Tab>
            <Icon as={CheckIcon} color="green" />
            &nbsp;ACTIVE&nbsp;
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme="green"
            >
              {myNodesOnline.length}
            </Tag>
          </Tab>
          <Tab>
            <Icon as={RepeatClockIcon} color="yellow.400" />
            &nbsp;PENDING&nbsp;
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme="yellow"
            >
              {myNodesPending.length}
            </Tag>
          </Tab>
          <Tab>
            <Icon as={WarningTwoIcon} color="white.500" />
            &nbsp;EXPIRED&nbsp;
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme="gray"
            >
              {myNodesExpired.length}
            </Tag>
          </Tab>
          <Tab>
            <Icon as={MinusIcon} color="red.500" />
            &nbsp;OFFLINE&nbsp;
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme="red"
            >
              {myNodesOffline.length}
            </Tag>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {' '}
            {/* ALL */}
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {/* <Flex flexWrap="wrap"> */}
              {isLoadingMyNodes ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              ) : (
                myNodesAll?.map((proj, index) => (
                  <div key={proj.nodeId}>
                    {/* <Link
                      _hover={{ textDecoration: 'none' }}
                      onClick={() =>
                        onOpenBuyNodeModal(
                          proj,
                          getNodeDetail(proj.nodeId)!,
                          proj.ownerNodeId,
                        )
                      }
                    > */}
                    <Box
                      bg="bg.surface"
                      boxShadow="sm"
                      borderRadius="lg"
                      p={2}
                      maxW="sm"
                      borderWidth="1px"
                      overflow="hidden"
                      m={2}
                      position="relative"
                    >
                      <Flex dir="row" pb={2}>
                        <Tooltip label="Node ID" placement="top-start">
                          <Badge
                            borderRadius="full"
                            px="2"
                            colorScheme="purple"
                          >
                            {proj.ownerNodeId}
                          </Badge>
                        </Tooltip>
                        <Spacer />
                        <Tooltip label="Node Status" placement="top-start">
                          <Badge
                            borderRadius="full"
                            px="2"
                            colorScheme={getStatusColor(proj.status)}
                          >
                            {proj.status}
                          </Badge>
                        </Tooltip>
                      </Flex>

                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={getNodeDetail(proj.nodeId)!.logoUrl}
                          alt={getNodeDetail(proj.nodeId)!.title}
                          w="100%"
                          h="80px"
                        />
                      </AspectRatio>

                      <Flex dir="row" pt={2} pb={4}>
                        <Spacer />
                        <Tooltip label="Created Time" placement="top-start">
                          <Badge variant="outline" colorScheme="green">
                            {moment(proj.lastBoughtDate).format('DD/MMM/YYYY')}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() =>
                            onOpenBuyNodeModal(
                              proj,
                              getNodeDetail(proj.nodeId)!,
                              proj.ownerNodeId,
                            )
                          }
                        >
                          Node Detail
                        </Button>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() => onOpenCompleteFormModal()}
                        >
                          Complete Form.
                        </Button>
                      </Flex>
                    </Box>
                    {/* </Link> */}
                    <Spacer />
                  </div>
                ))
              )}
            </Grid>
          </TabPanel>
          <TabPanel>
            {' '}
            {/* ACTIVE */}
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {isLoadingMyNodes ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              ) : (
                myNodesOnline?.map((proj) => (
                  <div key={proj.nodeId}>
                    {/* <Link
                      _hover={{ textDecoration: 'none' }}
                      onClick={() =>
                        onOpenBuyNodeModal(
                          proj,
                          getNodeDetail(proj.nodeId)!,
                          proj.ownerNodeId,
                        )
                      }
                    > */}
                    <Box
                      bg="bg.surface"
                      boxShadow="sm"
                      borderRadius="lg"
                      p={2}
                      maxW="sm"
                      borderWidth="1px"
                      overflow="hidden"
                      m={2}
                      position="relative"
                    >
                      <Flex dir="row" pb={2}>
                        <Tooltip label="Node ID" placement="top-start">
                          <Badge
                            borderRadius="full"
                            px="2"
                            colorScheme="purple"
                          >
                            {proj.ownerNodeId}
                          </Badge>
                        </Tooltip>
                        <Spacer />
                        <Tooltip label="Node Status" placement="top-start">
                          <Badge borderRadius="full" px="2" colorScheme="teal">
                            {proj.status}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={getNodeDetail(proj.nodeId)!.logoUrl}
                          alt={getNodeDetail(proj.nodeId)!.title}
                          w="100%"
                          h="80px"
                        />
                      </AspectRatio>
                      <Flex dir="row" pt={2} pb={4}>
                        <Spacer />
                        <Tooltip label="Created Time" placement="top-start">
                          <Badge variant="outline" colorScheme="green">
                            {moment(proj.lastBoughtDate).format('DD/MMM/YYYY')}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() =>
                            onOpenBuyNodeModal(
                              proj,
                              getNodeDetail(proj.nodeId)!,
                              proj.ownerNodeId,
                            )
                          }
                        >
                          Node Detail
                        </Button>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() => onOpenCompleteFormModal()}
                        >
                          Complete Form
                        </Button>
                      </Flex>
                    </Box>
                    {/* </Link> */}
                    <Spacer />
                  </div>
                ))
              )}
            </Grid>
          </TabPanel>
          <TabPanel>
            {' '}
            {/* PENDING */}
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {isLoadingMyNodes ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              ) : (
                myNodesPending?.map((proj) => (
                  <div key={proj.nodeId}>
                    {/* <Link
                      _hover={{ textDecoration: 'none' }}
                      onClick={() =>
                        onOpenBuyNodeModal(
                          proj,
                          getNodeDetail(proj.nodeId)!,
                          proj.ownerNodeId,
                        )
                      }
                    > */}
                    <Box
                      bg="bg.surface"
                      boxShadow="sm"
                      borderRadius="lg"
                      p={2}
                      maxW="sm"
                      borderWidth="1px"
                      overflow="hidden"
                      m={2}
                      position="relative"
                    >
                      <Flex dir="row" pb={2}>
                        <Tooltip label="Node ID" placement="top-start">
                          <Badge
                            borderRadius="full"
                            px="2"
                            colorScheme="purple"
                          >
                            {proj.ownerNodeId}
                          </Badge>
                        </Tooltip>
                        <Spacer />
                        <Tooltip label="Node Status" placement="top-start">
                          <Badge borderRadius="full" px="2" colorScheme="teal">
                            {proj.status}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={getNodeDetail(proj.nodeId)!.logoUrl}
                          alt={getNodeDetail(proj.nodeId)!.title}
                          w="100%"
                          h="80px"
                        />
                      </AspectRatio>
                      <Flex dir="row" pt={2} pb={4}>
                        <Spacer />
                        <Tooltip label="Created Time" placement="top-start">
                          <Badge variant="outline" colorScheme="green">
                            {moment(proj.lastBoughtDate).format('DD/MMM/YYYY')}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() =>
                            onOpenBuyNodeModal(
                              proj,
                              getNodeDetail(proj.nodeId)!,
                              proj.ownerNodeId,
                            )
                          }
                        >
                          Node Detail
                        </Button>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() => onOpenCompleteFormModal()}
                        >
                          Complete Form
                        </Button>
                      </Flex>
                    </Box>
                    {/* </Link> */}
                    <Spacer />
                  </div>
                ))
              )}
            </Grid>
          </TabPanel>
          <TabPanel>
            {' '}
            {/* EXPIRED */}
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {isLoadingMyNodes ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              ) : (
                myNodesExpired?.map((proj) => (
                  <div key={proj.nodeId}>
                    {/* <Link
                      _hover={{ textDecoration: 'none' }}
                      onClick={() =>
                        onOpenBuyNodeModal(
                          proj,
                          getNodeDetail(proj.nodeId)!,
                          proj.ownerNodeId,
                        )
                      }
                    > */}
                    <Box
                      bg="bg.surface"
                      boxShadow="sm"
                      borderRadius="lg"
                      p={2}
                      maxW="sm"
                      borderWidth="1px"
                      overflow="hidden"
                      m={2}
                      position="relative"
                    >
                      <Flex dir="row" pb={2}>
                        <Tooltip label="Node ID" placement="top-start">
                          <Badge
                            borderRadius="full"
                            px="2"
                            colorScheme="purple"
                          >
                            {proj.ownerNodeId}
                          </Badge>
                        </Tooltip>
                        <Spacer />
                        <Tooltip label="Node Status" placement="top-start">
                          <Badge borderRadius="full" px="2" colorScheme="teal">
                            {proj.status}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={getNodeDetail(proj.nodeId)!.logoUrl}
                          alt={getNodeDetail(proj.nodeId)!.title}
                          w="100%"
                          h="80px"
                        />
                      </AspectRatio>
                      <Flex dir="row" pt={2} pb={4}>
                        <Spacer />
                        <Tooltip label="Created Time" placement="top-start">
                          <Badge variant="outline" colorScheme="green">
                            {moment(proj.lastBoughtDate).format('DD/MMM/YYYY')}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() =>
                            onOpenBuyNodeModal(
                              proj,
                              getNodeDetail(proj.nodeId)!,
                              proj.ownerNodeId,
                            )
                          }
                        >
                          Node Detail
                        </Button>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() => onOpenCompleteFormModal()}
                        >
                          Complete Form
                        </Button>
                      </Flex>
                    </Box>
                    {/* </Link> */}
                    <Spacer />
                  </div>
                ))
              )}
            </Grid>
          </TabPanel>
          <TabPanel>
            {' '}
            {/* Offline */}
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {isLoadingMyNodes ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              ) : (
                myNodesOffline?.map((proj) => (
                  <div key={proj.nodeId}>
                    {/* <Link
                      _hover={{ textDecoration: 'none' }}
                      onClick={() =>
                        onOpenBuyNodeModal(
                          proj,
                          getNodeDetail(proj.nodeId)!,
                          proj.ownerNodeId,
                        )
                      }
                    > */}
                    <Box
                      bg="bg.surface"
                      boxShadow="sm"
                      borderRadius="lg"
                      p={2}
                      maxW="sm"
                      borderWidth="1px"
                      overflow="hidden"
                      m={2}
                      position="relative"
                    >
                      <Flex dir="row" pb={2}>
                        <Tooltip label="Node ID" placement="top-start">
                          <Badge
                            borderRadius="full"
                            px="2"
                            colorScheme="purple"
                          >
                            {proj.ownerNodeId}
                          </Badge>
                        </Tooltip>
                        <Spacer />
                        <Tooltip label="Node Status" placement="top-start">
                          <Badge borderRadius="full" px="2" colorScheme="teal">
                            {proj.status}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={getNodeDetail(proj.nodeId)!.logoUrl}
                          alt={getNodeDetail(proj.nodeId)!.title}
                          w="100%"
                          h="80px"
                        />
                      </AspectRatio>
                      <Flex dir="row" pt={2} pb={4}>
                        <Spacer />
                        <Tooltip label="Created Time" placement="top-start">
                          <Badge variant="outline" colorScheme="green">
                            {moment(proj.lastBoughtDate).format('DD/MMM/YYYY')}
                          </Badge>
                        </Tooltip>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() =>
                            onOpenBuyNodeModal(
                              proj,
                              getNodeDetail(proj.nodeId)!,
                              proj.ownerNodeId,
                            )
                          }
                        >
                          Node Detail
                        </Button>
                      </Flex>
                      <Flex dir="row" pb={2}>
                        <Button
                          w="100%"
                          onClick={() => onOpenCompleteFormModal()}
                        >
                          Complete Form
                        </Button>
                      </Flex>
                    </Box>
                    {/* </Link> */}
                    <Spacer />
                  </div>
                ))
              )}
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Node Detail Modal */}
      <Modal
        initialFocusRef={nodeDetailInitialRef}
        finalFocusRef={nodeDetailFinalRef}
        onClose={onCloseBuyNodeModal}
        isOpen={isOpenNodeDetail}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          {/* textAlign="center" */}
          <ModalHeader>
            <Text fontSize="2xl">My Node</Text>
            <Divider size="2"></Divider>
            <Text pt="2">
              {/* Subscription - {selectedBuyNodeInfo?.title} */}
              Subscription Detail
            </Text>
            {/* logoUrl, 
              title,
              description,
              websiteUrl */}
          </ModalHeader>
          <ModalCloseButton onClick={onCloseBuyNodeModal} />
          <ModalBody>
            <Card
              direction={{ base: 'column', sm: 'row' }}
              overflow="hidden"
              variant="outline"
            >
              <Image
                objectFit="cover"
                maxW={{ base: '100%', sm: '200px' }}
                src={selectedNodeInfo.logoUrl}
                alt={selectedNodeInfo.title}
              />

              <Stack>
                <CardBody>
                  <Heading size="md">
                    <Link
                      href={selectedNodeInfo.websiteUrl}
                      isExternal
                      _hover={{ textDecoration: 'none' }}
                    >
                      {selectedNodeInfo.title}
                    </Link>
                    &nbsp;
                    <Badge>{selectedNodeInfo.subtitle}</Badge>
                  </Heading>
                  <Text py="2">{selectedNodeInfo.description}</Text>
                  <Text as="sup" color="gray">
                    <Tooltip label="Node Status" placement="top-start">
                      <Badge
                        borderRadius="full"
                        px="2"
                        colorScheme={getStatusColor(selectedBuyNodeInfo.status)}
                      >
                        {selectedBuyNodeInfo.status}
                      </Badge>
                    </Tooltip>
                  </Text>
                  <Text as="sup" color="gray">
                    <Link
                      href={selectedNodeInfo.websiteUrl}
                      _hover={{ textDecoration: 'none' }}
                    >
                      Website <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Text>
                </CardBody>
              </Stack>
            </Card>
            <br />
            <HStack spacing={20} mb={2}>
              <FormControl>
                <Text as="b">Last Bought Date</Text>
              </FormControl>
              <FormControl>
                <Text>
                  {moment(selectedBuyNodeInfo.lastBoughtDate).format(
                    'DD/MMM/YYYY',
                  )}
                </Text>
              </FormControl>
            </HStack>
            <HStack spacing={20} mb={2}>
              <FormControl>
                <Text as="b">Expiry Date</Text>
              </FormControl>
              <FormControl>
                <Text>
                  {moment(selectedBuyNodeInfo.expiryDate).format('DD/MMM/YYYY')}
                </Text>
              </FormControl>
            </HStack>
            <HStack spacing={20} mb={2}>
              <FormControl>
                <Text as="b">Price (USDT)</Text>
              </FormControl>
              <FormControl>
                <Text>
                  $
                  {selectedNodeInfo?.price
                    ? formattedNumber(
                        Number(getPrice(String(periodMonth))).toFixed(4),
                      )
                    : 'n/a'}{' '}
                  / Month
                </Text>
              </FormControl>
            </HStack>
            <HStack spacing={20} mb={5}>
              <FormControl>
                <Text as="b">Total</Text>
              </FormControl>
              <FormControl>
                {/* <Text> */}
                <Text fontSize="2xl" as="ins" fontWeight="bold">
                  $
                  {selectedNodeInfo?.price
                    ? formattedNumber(
                        (
                          (getPrice(String(periodMonth)) *
                            Number(periodMonth) *
                            (100 - discounPercent)) /
                          100
                        ).toFixed(4),
                      )
                    : 'n/a'}
                </Text>
                {/* </Text> */}
              </FormControl>
            </HStack>
            <HStack spacing={20} mb={5}>
              <Text as="sup" color="gray">
                (Price * Month) * discount code = Total
              </Text>
            </HStack>
            <HStack spacing={20} mb={5}>
              <Text as="sup" color="gray">
                ({formattedNumber(getPrice(String(periodMonth)))} *{' '}
                {Number(periodMonth)}) - {100 - discounPercent} ={' '}
                {'$' +
                  formattedNumber(
                    (
                      (getPrice(String(periodMonth)) *
                        Number(periodMonth) *
                        (100 - discounPercent)) /
                      100
                    ).toFixed(4),
                  )}
              </Text>
            </HStack>

            <Divider mb={5} />

            <Stack spacing={3}>
              <FormControl>
                <FormLabel>Referral Code (optional)</FormLabel>
                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                  <GridItem colSpan={4} h="10">
                    <Input
                      placeholder="Referral Code"
                      size="sm"
                      ref={nodeDetailInitialRef}
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                  </GridItem>
                  <GridItem colStart={5} colEnd={6} h="10">
                    <ButtonGroup>
                      {referralCode && (
                        <Button onClick={() => onClearReferralCode()}>
                          Clear
                        </Button>
                      )}
                      <Button
                        isDisabled={!referralCode}
                        onClick={() => onApplyReferralCode()}
                      >
                        Apply
                      </Button>
                    </ButtonGroup>
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl>
                <FormLabel>Period (Months)</FormLabel>
                <ButtonGroup isAttached>
                  <Button
                    variant={periodMonth === '1' ? 'solid' : 'outline'}
                    color={periodMonth === '1' ? 'white' : 'black'}
                    onClick={() => setPeriodMonth('1')}
                  >
                    1
                  </Button>
                  <Button
                    variant={periodMonth === '3' ? 'solid' : 'outline'}
                    color={periodMonth === '3' ? 'white' : 'black'}
                    onClick={() => setPeriodMonth('3')}
                  >
                    3
                  </Button>
                  <Button
                    variant={periodMonth === '6' ? 'solid' : 'outline'}
                    color={periodMonth === '6' ? 'white' : 'black'}
                    onClick={() => setPeriodMonth('6')}
                  >
                    6
                  </Button>
                  <Button
                    variant={periodMonth === '9' ? 'solid' : 'outline'}
                    color={periodMonth === '9' ? 'white' : 'black'}
                    onClick={() => setPeriodMonth('9')}
                  >
                    9
                  </Button>
                  <Button
                    variant={periodMonth === '12' ? 'solid' : 'outline'}
                    color={periodMonth === '12' ? 'white' : 'black'}
                    onClick={() => setPeriodMonth('12')}
                  >
                    12
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Stack>

            <Divider my={5} />

            <HStack spacing={3}>
              <FormControl>
                <FormLabel>Public Key</FormLabel>
                <InputGroup size="md">
                  {/* Public Key */}
                  <Input
                    pr="4.5rem"
                    type={showPublicKey ? 'text' : 'password'}
                    placeholder="Public Key"
                    value={selectedBuyNodeInfo?.publicKey}
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      aria-label="Public Key"
                      h="1.75rem"
                      size="sm"
                      onClick={onClickShowPublicKey}
                    >
                      {showPublicKey ? (
                        <Icon as={ViewOffIcon} />
                      ) : (
                        <Icon as={ViewIcon} />
                      )}
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Private Key</FormLabel>
                {/* Private Key */}
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showPrivateKey ? 'text' : 'password'}
                    placeholder="Private Key"
                    value={selectedBuyNodeInfo?.displayedPrivateKey}
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      aria-label="Public Key"
                      h="1.75rem"
                      size="sm"
                      onClick={onClickShowPrivateKey}
                    >
                      {showPrivateKey ? (
                        <Icon as={ViewOffIcon} />
                      ) : (
                        <Icon as={ViewIcon} />
                      )}
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </HStack>
          </ModalBody>
          <ModalFooter>
            {chainId === MainChainID ? (
              tokenAllowance >=
              ethers.parseUnits(
                (
                  (getPrice(String(periodMonth)) *
                    Number(periodMonth) *
                    (100 - discounPercent)) /
                  100
                ).toFixed(4),
                6,
              ) ? (
                tokenBalance >
                (getPrice(String(periodMonth)) *
                  Number(periodMonth) *
                  (100 - discounPercent)) /
                  100 ? (
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onRenew(
                        Number(selectedNodeInfo?.id),
                        Number(periodMonth),
                        currentOwnerNodeID,
                        referralCode,
                      )
                    }}
                  >
                    Renew
                  </Button>
                ) : (
                  <Button colorScheme="gray" mr={3}>
                    Insufficient funds
                  </Button>
                )
              ) : (
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() =>
                    notificationToast(
                      sendTxApproveToken(
                        (getPrice(String(periodMonth)) *
                          Number(periodMonth) *
                          (100 - discounPercent)) /
                          100,
                      ),
                    )
                  }
                >
                  Approve token
                </Button>
              )
            ) : (
              <Button colorScheme="red" mr={3} onClick={openChainModal}>
                Wrong network
              </Button>
            )}

            <Button onClick={onCloseBuyNodeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* setIsOpenCompleteForm */}
      <Modal
        initialFocusRef={completeFormInitialRef}
        finalFocusRef={completeFormFinalRef}
        onClose={onCloseCompleteFormModal}
        isOpen={isOpenCompleteForm}
        isCentered
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          {/* textAlign="center" */}
          <ModalHeader>
            <Text fontSize="2xl">Enter Detail</Text>
            <Divider size="2"></Divider>
          </ModalHeader>
          <ModalCloseButton onClick={onCloseCompleteFormModal} />
          <ModalBody>
            <Stack spacing={3}>
              <FormControl>
                <FormLabel>Farcaster ID</FormLabel>
                <Grid>
                  <GridItem colSpan={4} h="10">
                    <Input placeholder="Enter Farcaster ID" size="sm" />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl>
                <FormLabel>Optimism Mainnet RPC URL</FormLabel>
                <Grid>
                  <GridItem colSpan={4} h="10">
                    <Input
                      placeholder="Enter Optimism Mainnet RPC URL"
                      size="sm"
                    />
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl>
                <FormLabel>Ethereum Mainnet RPC URL</FormLabel>
                <Grid>
                  <GridItem colSpan={4} h="10">
                    <Input
                      placeholder="Enter Ethereum Mainnet RPC URL"
                      size="sm"
                    />
                  </GridItem>
                </Grid>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button w="100%" onClick={onCloseCompleteFormModal}>
              Submit
            </Button>
            {/* <Button onClick={onCloseCompleteFormModal}>Cancel</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default MyNodesPage
